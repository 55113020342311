import React, { useContext, useState } from "react";
import { Container, Row, Col, ProgressBar } from "react-bootstrap";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import ClockLoader from "react-spinners/ClockLoader";
import ReactGA from "react-ga";
import map from "async/map";
import waterfall from "async/waterfall";
import { AppContext } from "./../../AppContext";
import Header from "./../../components/Header";
import LOGO from "./../../assets/logo.png";
import HOMEIMG from "./../../assets/home.png";
import { AppButton } from "./../../components/Button";
import { getFile, getImage, getFileTest } from "./../../services";

const ContainerWrapper = styled(Container)`
  margin-bottom: 30px;
`;
const Div = styled.div`
  margin-top: 130px;
  /* margin-bottom: 60px; */
  h1 {
    font-family: Armata-Regular;
    font-size: 22px;
    text-align: center;
  }
  & {
    @media screen and (max-width: 600px) {
      margin-top: 110px;
    }
  }
`;
const RowW = styled(Row)`
  padding: 0;
  margin: 0;
  background-color: #e8e6e0 !important;
`;
const IMG = styled.img`
  width: 100px;
  height: 154px;
`;

const Languages = () => {
  let history = useHistory();
  const { languages, setLanguage, collections, setCollections } = useContext(
    AppContext
  );

  const [loading, setLoading] = useState(false);
  const [percent, setPercent] = useState(0);

  const selectLanguage = (item) => {
    setLanguage(item);
    runGA(item);
    loadData(item);
  };

  const loadData = (lang) => {
    const _collections = collections;
    let count = 0;
    let total = 0;
    for (let i = 0; i < collections.length; i++) {
      for (let j = 0; j < collections[i].obras.length; j++) {
        if (collections[i].obras[j].audio[lang.abreviatura]) {
          total++;
        }
        if (collections[i].obras[j].imagen_id) {
          total++;
        }
      }
    }

    setLoading(true);
    map(
      _collections,
      (collection, cb) => {
        map(
          collection.obras,
          (obra, cbs) => {
            waterfall(
              [
                (callback) => {
                  if (obra.audio[lang.abreviatura]) {
                    getFile(obra.audio[lang.abreviatura])
                      // getFileTest()
                      .then((response) => {
                        const url = URL.createObjectURL(response.data);
                        obra.audioUrl = url;
                        count++;
                        setPercent(parseInt((count * 100) / total));
                        callback(null, obra);
                      })
                      .catch((err) => {
                        callback(err, null);
                      });
                  } else {
                    callback(null, obra);
                  }
                },
                (arg1, callback) => {
                  if (arg1.imagen_id) {
                    getImage(arg1.imagen_id)
                      .then((response) => {
                        const url = URL.createObjectURL(response.data);
                        arg1.imageUrl = url;
                        count++;
                        setPercent(parseInt((count * 100) / total));
                        callback(null, arg1);
                      })
                      .catch((err) => {
                        callback(err, null);
                      });
                  } else {
                    callback(null, arg1);
                  }
                },
              ],
              function (err, result) {
                if (err) {
                  cbs(err, null);
                } else {
                  cbs(null, result);
                }
              }
            );
          },
          (err, results) => {
            if (err) {
              cb(err, null);
            } else {
              collection.obras = results;
              cb(null, collection);
            }
          }
        );
      },
      (err, results) => {
        // results is now an array of stats for each file
        if (err) {
          console.log(err);
        } else {
          setCollections(results);
          history.push("/");
        }
        setLoading(false);
      }
    );
  };

  const runGA = (item) => {
    ReactGA.event({
      category: "Language",
      action: `Selected -${item.nombre}`,
      value: parseInt(item.id),
    });
  };

  return (
    <React.Fragment>
      <Header />
      <Div>
        <h1>Audioguía</h1>
      </Div>
      {!loading && (
        <ContainerWrapper>
          <Row>
            <Col>
              <img className="w-100" src={HOMEIMG} alt="" />
            </Col>
          </Row>
          {languages.length < 1 && (
            <Row>
              <Col className="d-flex justify-content-center">
                <ClockLoader size={100} color={"#D58A95"} loading={true} />
              </Col>
            </Row>
          )}
          {languages.length > 0 &&
            languages.map((item) => {
              return (
                <Row key={`lang-${item.id}`} className="mt-4">
                  <Col className="d-flex justify-content-center">
                    <AppButton
                      onClick={() => {
                        selectLanguage(item);
                      }}
                    >
                      <span>{item.nombre}</span>&nbsp;
                    </AppButton>
                  </Col>
                </Row>
              );
            })}
        </ContainerWrapper>
      )}

      {loading && (
        <ContainerWrapper>
          <RowW>
            <Col
              style={{ marginTop: 120 }}
              className="d-flex justify-content-center align-items-center"
            >
              <div>
                <IMG src={LOGO} alt="" />
              </div>
            </Col>
          </RowW>
          <Row>
            <Col className="p-5">
              Cargando Datos ... <span>({`${percent}%`})</span>
              <ProgressBar now={percent} label={`${percent}%`} srOnly />
            </Col>
          </Row>
        </ContainerWrapper>
      )}
    </React.Fragment>
  );
};

export default Languages;
