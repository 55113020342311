import React, { useEffect, useState } from "react";
import { getMaps, getFile } from "./../../services";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import styled from "styled-components";
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';


const AppButton = styled(Button)`
  color: #000000;
  background-color: transparent;
  font-family: Montserrat-Regular;
  letter-spacing: 1px;
  font-size: 16px;
  width: 300px;
  padding: 10px 0px;
  border-radius: 20px;
  border: 1px solid #d58a95;
  &:hover {
    outline: none;
    box-shadow: 0 0 3pt 2pt #d58a95;
    color: #000000;
    background-color: transparent;
    border-color: #d58a95;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 3pt 2pt #d58a95;
    color: #000000;
    background-color: transparent;
    border-color: #d58a95;
  }
`;

const Div = styled.div`
  height: 95vh;
`;

const Maps = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pdf, setPdf] = useState(null);

  useEffect(() => {
    fetch();
  }, []);



  const fetchPdf = async (id) => {
    try {
      setLoading(`load-${id}`);
      const { data } = await getFile(id);
      const url = URL.createObjectURL(data);

      setPdf(url);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetch = async () => {
    try {
      setLoading();
      const res = await getMaps();
      setData(res.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Div className="d-flex align-items-center w-100">
      {!pdf && (
        <Row className="d-flex align-items-center m-0 w-100">
          {data.map((item) => {
            return (
              <Col className="col-12 my-2 d-flex justify-content-center">
                <AppButton
                  onClick={() => {
                    fetchPdf(item.mapa_id);
                  }}
                >
                  {loading === `load-${item.mapa_id}` && (
                    <Spinner
                      style={{ width: "1.5em", height: "1.5em" }}
                      animation="grow"
                    />
                  )}
                  {!loading !== `load-${item.mapa_id}` && (
                    <span>{item.boton}</span>
                  )}
                </AppButton>
              </Col>
            );
          })}
        </Row>
      )}
      {pdf && (
        <Row className="w-100 h-100">
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <div className="w-100">
              <Viewer fileUrl={pdf} />
            </div>
          </Worker>
        </Row>
      )}
    </Div>
  );
};

export default Maps;
