import axios from "axios";
import { reactLocalStorage } from "reactjs-localstorage";
/* const HOST_API = "https://catedralgirona.audioguiarte-pwa.com/admin/public/api"; */
/* const HOST_API = "https://a3a362f7-a8d1-41ba-bab6-ef3b1ea497ad.clouding.host/museos/catedralgirona/admin/public/api"; */

const HOST_API = "https://dda3c472-b2e9-4b1b-8b80-591bd82dfeb2.clouding.host/girona/admin/public/api";

const password = "secret";

export const login = () => {
  return axios({
    method: "post",
    url: `${HOST_API}/code/login`,
    data: {
      password,
    },
  });
};
export const getMaps = (id, idioma_id) => {
  const token = reactLocalStorage.get("token", false);

  return axios({
    method: "get",
    url: `${HOST_API}/mapas`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const sendQuestionnaire = (questionary) => {
  const token = reactLocalStorage.get("token", false);
  return axios({
    method: "post",
    url: `${HOST_API}/cuestionario/store`,
    data: {
      cuestionario: questionary,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCollections = (idioma_id) => {
  const token = reactLocalStorage.get("token", false);
  return axios({
    method: "get",
    url: `${HOST_API}/colecciones`,
    params: {
      idioma_id,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAllData = (code = null) => {
  const token = reactLocalStorage.get("token", false);
  return axios({
    method: "get",
    url: `${HOST_API}/todo`,
    params: { code },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getLanguages = () => {
  const token = reactLocalStorage.get("token", false);
  return axios({
    method: "get",
    url: `${HOST_API}/idiomas`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCollection = (id, idioma_id) => {
  const token = reactLocalStorage.get("token", false);

  return axios({
    method: "get",
    url: `${HOST_API}/colecciones/${id}`,
    params: { idioma_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getImage = (id) => {
  const token = reactLocalStorage.get("token", false);
  return axios({
    method: "get",
    url: `${HOST_API}/file/download/${id}`,
    responseType: "blob", // important
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getFile = (id) => {
  const token = reactLocalStorage.get("token", false);
  return axios({
    method: "get",
    url: `${HOST_API}/file/download/${id}`,
    responseType: "blob", // important
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getFileTest = () => {
  // const token = reactLocalStorage.get("token", false);
  return axios({
    method: "get",
    url: `http://localhost:3000/audios/1/es/1.mp3`,
    responseType: "blob", // important
    headers: {
      // Authorization: `Bearer ${token}`,
    },
  });
};
