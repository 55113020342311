import React, { useState, useEffect } from "react";

const Player = ({ url, state }) => {
  return (
    <audio src={url} controls controlsList="nodownload">
      Error: your web browser does not support this audio player.
    </audio>
    // <ReactPlayer className="react-player-audio" url={url} playing={state} />
  );
};

const Audio = ({ url }) => {
  const [ra, setRa] = useState(Math.floor(Math.random() * 11));

  useEffect(() => {
    setRa(Math.random() * 11);
  }, [url]);

  return (
    <React.Fragment>
      <Player key={ra} url={url} />
    </React.Fragment>
  );
};

export default React.memo(Audio, (prevProps, props) => {
  return prevProps.url === props.url;
});
